<template >
    <div v-if="loadingSpinner"
         class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-500 bg-opacity-75 flex flex-col items-center justify-center" >
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4" ></div >
        <h2 class="text-center text-white text-xl font-semibold" >Wird geladen...</h2 >
        <p class="w-1/3 text-center text-white" >Dies kann wenige Sekunden dauern. Bitte die Seite nicht schließen.</p >
    </div >

    <div class="flex flex-col" >
        <div class="flex justify-end mb-7" >
            <div >
                <Menu as="div" class="relative inline-block text-left" >
                    <div >
                        <MenuButton
                            class="h-10 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-primary border border-primary shadow-sm hover:bg-gray-50" >
                            <InboxArrowDownIcon class="h-5 inline-block mr-1" />
                            Export
                            <ChevronDownIcon class="h-5 inline-block ml-1" ></ChevronDownIcon >
                        </MenuButton >
                    </div >

                    <transition enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95" >
                        <MenuItems
                            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" >
                            <div class="py-1" >
                                <MenuItem v-slot="{ active }" class="hover:bg-gray-100" >
                                    <a :href="exportOverviewUrl" target="_blank" download
                                       class="block px-4 py-2 text-sm" >Download .csv (Einfach)</a >
                                </MenuItem >
                                <MenuItem v-slot="{ active }" class="hover:bg-gray-100" >
                                    <a :href="exportExpandedUrl" target="_blank" download
                                       class="block px-4 py-2 text-sm" >Download .csv (Erweitert)</a >
                                </MenuItem >
                            </div >
                        </MenuItems >
                    </transition >
                </Menu >
            </div >
        </div >

        <div class="mb-5 flex justify-between flex-wrap space-y-4 space-x-4" >
            <div class="flex md:space-x-5 space-y-5 md:space-y-0 flex-wrap" >
                <div class="w-full md:w-auto" >
                    <input type="text"
                           class="block w-full rounded-md border-gray-300 px-4 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                           placeholder="Suchen..." v-model="query" />
                </div >

                <div class="w-full md:w-auto" >
                    <vue-tailwind-datepicker
                        v-model="dateRange"
                        as-single
                        use-range
                        input-classes="block w-full rounded-md border-gray-300 px-4 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                        placeholder="Datum auswählen"
                        separator=" - "
                        :formatter="{date: 'DD.MM.YY', month: 'MMM'}"
                        :disable-date="(date) => date > new Date"
                        :options="{
                            shortcuts: {
                                today: 'Heute',
                                yesterday: 'Gestern',
                                past: period =>  `letzten ${period} Tage`,
                                currentMonth: 'Dieser Monat',
                                pastMonth: 'Letzter Monat'
                           },
                           footer: {
                            apply: 'Auswählen',
                            cancel: 'Abbrechen'
                          }
                        }"
                    />
                </div >

                <div class="w-full md:w-auto" >
                    <Menu as="div" class="relative inline-block text-left" >
                        <div >
                            <MenuButton
                                class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100" >
                                {{ options.perPage }} Einträge
                                <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                            </MenuButton >
                        </div >

                        <transition enter-active-class="transition ease-out duration-100"
                                    enter-from-class="transform opacity-0 scale-95"
                                    enter-to-class="transform opacity-100 scale-100"
                                    leave-active-class="transition ease-in duration-75"
                                    leave-from-class="transform opacity-100 scale-100"
                                    leave-to-class="transform opacity-0 scale-95" >
                            <MenuItems v-model="options.perPage"
                                       class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" >
                                <div class="py-1" >
                                    <MenuItem v-slot="{ active }" >
                                        <div @click="options.perPage = 15"
                                             :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" >
                                            15 Einträge
                                        </div >
                                    </MenuItem >
                                    <MenuItem v-slot="{ active }" >
                                        <div @click="options.perPage = 50"
                                             :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" >
                                            50 Einträge
                                        </div >
                                    </MenuItem >
                                    <MenuItem v-slot="{ active }" >
                                        <div @click="options.perPage = 100"
                                             :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" >
                                            100 Einträge
                                        </div >
                                    </MenuItem >
                                    <MenuItem v-slot="{ active }" >
                                        <div @click="options.perPage = 200"
                                             :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" >
                                            200 Einträge
                                        </div >
                                    </MenuItem >
                                </div >
                            </MenuItems >
                        </transition >
                    </Menu >
                </div >
                <div >
                    <Combobox as="div" class="h-full" v-model="selectedTags" multiple >
                        <div class="relative h-full" >
                            <ComboboxInput
                                class="w-full h-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-[22px] text-gray-700"
                                @change="query = $event.target.value" :display-value="(tag) => tag" />
                            <ComboboxButton
                                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none w-full" >
                                <div
                                    :class="['flex items-center w-full', !selectedTags.length ? 'justify-between': 'justify-end']" >
                                    <div class="text-gray-400 flex items-center" v-if="!selectedTags.length" >
                                        <TagIcon class="h-4 inline-block mr-1" />
                                        <span class="text-sm" >Tag auswählen</span >
                                    </div >
                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div >
                            </ComboboxButton >

                            <ComboboxOptions
                                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm" >
                                <ComboboxOption v-for="tag in tags" :key="tag" :value="tag.name" as="template"
                                                v-slot="{ active, selected }" >
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9 text-gray-700', active ? 'bg-gray-200 text-gray-700' : 'text-gray-700']" >
                                        <div
                                            :class="['truncate flex flex-row items-center', selected && 'font-semibold']" >
                                            <div class="h-2 w-2 rounded-full mr-2"
                                                 :style="`background-color: ${tag.color};`" ></div >
                                            {{ tag.name }}
                                        </div >

                                        <span v-if="selected"
                                              :class="['text-primary absolute inset-y-0 right-0 flex items-center pr-4']" >
                                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span >
                                    </li >
                                </ComboboxOption >
                            </ComboboxOptions >
                        </div >
                    </Combobox >
                </div >
            </div >
        </div >

        <div class="flex md:justify-end md:space-x-3 space-y-3 md:space-y-0 flex-wrap mb-6" >
            <div class="flex justify-center items-center" >
                <button type="button"
                        :class="['ml-2 inline-flex items-center rounded-md bg-white px-3 py-1.5 text-sm font-base leading-4 text-gray-700 hover:bg-slate-100 border border-slate-200', {'!bg-slate-200 !border-slate-300': filterGroupValue === 'favored'}]"
                        @click="filterGroupValue === 'favored' ? filterGroupValue = null : filterGroupValue = 'favored'" >
                    <StarIcon
                        :class="['h-5', {'text-gray-500': filterGroupValue !== 'favored'}, {'fill-yellow-400 text-yellow-400': filterGroupValue === 'favored'}]" />
                </button >

                <button v-for="filter in filterGroup" :key="filter.value" type="button"
                        :class="['ml-2 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-base leading-4 text-gray-700 hover:bg-slate-100 border border-slate-200', {'!bg-slate-200 !border-slate-300': filterGroupValue === filter.value}]"
                        @click="filterGroupValue === filter.value ? filterGroupValue = null : filterGroupValue = filter.value" >
                    {{ filter.label }}
                </button >
            </div >
        </div >

        <div >
            <v-server-table
                url="/api/rma"
                :columns="columns"
                :options="options"
                ref="table"
                @rowClick=openDetailsModal
            >
                <template v-slot:is_favored="props" >
                    <div class="cursor-pointer do-not-click" @click="toggleFavorite(props.row)" >
                        <StarIcon
                            :class="['h-5 hover:fill-yellow-400', {'text-gray-500': !props.row.is_favored}, {'fill-yellow-400 text-yellow-400': props.row.is_favored}]" />
                    </div >
                </template >

                <template v-slot:read="props" >
                    <div class="cursor-pointer do-not-click -m-3.5 p-3.5 w-full h-full"
                         @click="toggleReadStatus(props['row'])" >
                        <div v-if="!props['row'].read"
                             class="h-2 w-2 text-gray-600 hover:text-gray-900 bg-secondary rounded-full" ></div >
                    </div >
                </template >
                <template v-slot:created_at="props" >
                    <FormatDate :date="props['row'].created_at" />
                </template >
                <template v-slot:status="props" >
                    <div class="flex items-center" >
                        <div class="inline-flex items-center rounded-full px-3 py-0.5 text-sm text-gray-700 ml-2"
                             :class="statusClass(props.row['status'])" >
                            {{ props.row['status'] }}
                        </div >
                    </div >
                </template >
                <template v-slot:tag="props" >
                    <div class="flex items-center" v-if="props.row.tag" >
                        <div
                            class="inline-flex items-center rounded-full px-3 py-0.5 text-sm"
                            :style="`color:${getTagByName(props.row.tag)?.color}; background-color: ${getTagByName(props.row.tag)?.color}19; border: solid 0.5px ${getTagByName(props.row.tag)?.color}30;`" >
                            <TagIcon class="h-4 w-4 min-h-4 min-w-4 inline-block -ml-1 mr-1" />
                            <span class="text-xs text-center"
                                  :style="`color:${getTagByName(props.row.tag)?.color};`" >{{ props.row.tag }}</span >
                        </div >
                    </div >
                </template >
            </v-server-table >
        </div >
        <Modal :show="this.showDetails" @modalClose="this.showDetails = false" >
            <template v-slot:body >
                <RmaDetails :rmaId="this.rma.id" @update="$refs.table.getData()" @opened="reloadData" ></RmaDetails >
            </template >

            <template v-slot:footer >
                <ContactButtons referenceType="Retoure" :referenceId="this.rma.id" :show="['message']" />
            </template >
        </Modal >
    </div >
</template >
<script >
import {ArrowDownTrayIcon, EnvelopeOpenIcon, InboxArrowDownIcon, PlusIcon, StarIcon} from '@heroicons/vue/24/outline';
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
} from '@headlessui/vue'
import {CheckIcon, ChevronDownIcon, ChevronUpDownIcon, EnvelopeIcon, TagIcon} from '@heroicons/vue/20/solid'
import debounce from 'debounce';
import VueTailwindDatepicker from 'vue-tailwind-datepicker'
import Modal from "../components/Modal.vue";
import RmaDetails from "../components/RmaDetails.vue";
import ContactButtons from "../components/ContactButtons.vue";
import FormatDate from '../components/FormatDate';
import ProductDetails from "../components/ProductDetails.vue";
import Cookies from "js-cookie";

export default {
    components: {
        StarIcon,
        CheckIcon, TagIcon, ChevronUpDownIcon,
        ProductDetails,
        FormatDate,
        PlusIcon,
        ArrowDownTrayIcon,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        ChevronDownIcon,
        ContactButtons,
        Modal,
        RmaDetails,
        VueTailwindDatepicker,
        InboxArrowDownIcon,
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
        EnvelopeIcon,
        EnvelopeOpenIcon,
    },

    data() {
        return {
            query: '',
            dateRange: {
                startDate: '',
                endDate: ''
            },
            filterGroupColumn: 'status',
            filterGroupValue: '',
            loadingSpinner: false,
            filterGroup: [
                {
                    label: 'Alle',
                    value: '',
                },
                {
                    label: 'Ungesehen',
                    value: 'ungelesen',
                },
                {
                    label: 'Gesehen',
                    value: 'gelesen',
                },
            ],
            selectedTags: [],
            columns: [
                'read',
                'is_favored',
                'id',
                'customer',
                'order',
                'external_order',
                'created_at',
                'status',
                'tag',
            ],
            options: {
                perPage: 15,
                rowClassCallback: (row) => {
                    return !row.read ? 'font-semibold' : '';
                },
                headings: {
                    'read': '',
                    'is_favored': '',
                    'id': 'Retoure',
                    'customer': 'Absender',
                    'order': 'Auftrag',
                    'external_order': 'Externe Nr.',
                    'created_at': 'Erstelldatum',
                    'status': 'Status',
                    'tag': 'Tag',
                },
                texts: {
                    count: '{from} bis {to} von {count} Einträgen|{count} Einträge| Ein Eintrag',
                    filter: 'Filterergebnis:',
                    noResults: 'Keine Einträge vorhanden',
                    loading: 'Wird geladen',
                    filterPlaceholder: 'Suchen',
                },
                requestFunction(data) {
                    this.$parent.$parent.loadingSpinner = true;
                    data.query = this.$parent.$parent.query;
                    data.limit = this.$parent.$parent.options.perPage;
                    data['start_date'] = this.$parent.$parent.dateRange.startDate;
                    data['end_date'] = this.$parent.$parent.dateRange.endDate;

                    if (this.$parent.$parent.filterGroupValue && this.$parent.$parent.filterGroupColumn) {
                        data[this.$parent.$parent.filterGroupColumn] = this.$parent.$parent.filterGroupValue;
                    }

                    if (this.$parent.$parent.selectedTags.length) {
                        data['tags'] = this.$parent.$parent.selectedTags;
                    }

                    return axios.get(this.url, {
                        params: data
                    }).finally(() => {
                        this.$parent.$parent.loadingSpinner = false;
                    }).catch((e) => {
                        this.$parent.$parent.loadingSpinner = false;
                        this.dispatch('error', e);
                    });
                },
                columnsClasses: {
                    read: 'w-8',
                    is_favored: 'w-8',
                },
                templates: {},
            },
            debouncedFilter: null,
            rma: null,
            showDetails: false,
        };
    },

    computed: {
        tags() {
            return typeof Cookies.get('tags') === 'string' && typeof Cookies.get('tags') !== 'undefined' ? JSON.parse(Cookies.get('tags')) : [];
        },

        exportOverviewUrl() {
            let params = {
                query: this.query,
                start_date: this.dateRange.startDate,
                end_date: this.dateRange.endDate,
            }
            if (this.filterGroupValue && this.filterGroupColumn) {
                params[this.filterGroupColumn] = this.filterGroupValue;
            }
            if (this.selectedTags.length) {
                params['tags'] = this.selectedTags
            }
            return "/api/rma/csv?" + new URLSearchParams(params).toString();
        },
        exportExpandedUrl() {
            let params = {
                query: this.query,
                start_date: this.dateRange.startDate,
                end_date: this.dateRange.endDate,
            }
            if (this.filterGroupValue && this.filterGroupColumn) {
                params[this.filterGroupColumn] = this.filterGroupValue;
            }
            if (this.selectedTags.length) {
                params['tags'] = this.selectedTags
            }
            return "/api/rma/csv/expanded?" + new URLSearchParams(params).toString();
        },
    },

    watch: {
        query(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.filter();
            }
        },
        dateRange(newValue, oldValue) {
            this.filter();
        },
        'options.perPage': {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$refs.table?.setLimit(this.options.perPage);
                }
            }
        },
        filterGroupValue(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.filter();
            }
        },
        selectedTags(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.filter();
            }
        },
    },

    methods: {
        getTagByName(name) {
            return this.tags.find(tag => tag.name === name);
        },

        filter() {
            this.debouncedFilter ? this.debouncedFilter() : this.filterTable();
        },

        filterTable() {
            this.$refs.table?.setFilter(this.query);
        },

        openSelectedRma(id) {
            this.rma = {
                id: id,
            };
            this.showDetails = true;
        },

        openDetailsModal(event) {
            if (event.event.target.closest('.do-not-click')) {
                return;
            }

            this.rma = event.row;
            this.showDetails = true;
        },

        reloadData() {
            this.$refs.table?.getData();
        },

        closeDetailsModal() {
            this.showDetails = false;
        },

        statusClass(status) {
            return {
                'bg-slate-200': status == 'offen',
                'bg-green-200': status == 'bearbeitet',
            }
        },

        filterIsRmaId() {
            return this.filterValue && !this.filterGroup.find((filter) => filter.value === this.filterValue);
        },

        toggleReadStatus(row) {
            let url = '';
            if (row.read) {
                url = `/api/rma/${row.id}/unread`;
            } else {
                url = `/api/rma/${row.id}/read`;
            }

            axios.put(url)
                .then((response) => {
                    if (response.status !== 200) {
                        return;
                    }

                    row.read = !row.read;
                });
        },

        toggleFavorite(row) {
            let url = '';
            if (row.is_favored) {
                url = `/api/rma/${row.id}/unfavorite`;
            } else {
                url = `/api/rma/${row.id}/favor`;
            }

            axios.put(url)
                .then((response) => {
                    if (response.status !== 200) {
                        return;
                    }

                    row.is_favored = !row.is_favored;
                });
        },
    },

    mounted() {
        this.debouncedFilter = debounce(() => {
            this.filterTable();
        }, 200)
    },

    beforeMount() {
        if (!this.filterValue) {
            return;
        }

        if (this.filterIsRmaId()) {
            this.openSelectedRma(this.filterValue);
        } else {
            this.filterGroupValue = this.filterValue;
        }
    },

    props: ['filterValue'],
}
</script >
