<template >
    <div v-if="currentRoutePath !== '/login' && currentRoutePath !== '/forgot-password'" >
        <TransitionRoot as="template" :show="sidebarOpen" >
            <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false" >
                <TransitionChild as="template" enter="transition-opacity ease-linear duration-300"
                                 enter-from="opacity-0" enter-to="opacity-100"
                                 leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
                                 leave-to="opacity-0" >
                    <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </TransitionChild >

                <div class="fixed inset-0 z-40 flex" >
                    <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                                     enter-from="-translate-x-full" enter-to="translate-x-0"
                                     leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                                     leave-to="-translate-x-full" >
                        <DialogPanel
                            class="relative flex w-full max-w-xs flex-1 flex-col bg-slate-100 flex justify-between" >
                            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                                             enter-to="opacity-100" leave="ease-in-out duration-300"
                                             leave-from="opacity-100" leave-to="opacity-0" >
                                <div class="absolute top-0 right-0 -mr-12 pt-2" >
                                    <button type="button"
                                            class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            @click="sidebarOpen = false" >
                                        <span class="sr-only" >Close sidebar</span >
                                        <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                                    </button >
                                </div >
                            </TransitionChild >
                            <div class="h-0 flex-1 pt-5 pb-4" >
                                <div class="flex flex-shrink-0 items-center justify-between px-4" >
                                    <img class="h-8 w-auto" src="/images/logo.webp"
                                         alt="Beckmann Systemlogistik GmbH" />
                                    <!--                                    <NotificationMenu ></NotificationMenu >-->
                                </div >
                                <nav class="mt-5 space-y-1 px-2" >
                                    <!--                                    <router-link-->
                                    <!--                                        v-show="onlyTeamAndAdminCanAccess"-->
                                    <!--                                        @mouseup="sidebarOpen = false"-->
                                    <!--                                        to="/team"-->
                                    <!--                                        :class="[currentRouteName === '/team' ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']" >-->
                                    <!--                                        <component is="ListBulletIcon"-->
                                    <!--                                                   :class="[currentRouteName === '/team' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']"-->
                                    <!--                                                   aria-hidden="true" />-->
                                    <!--                                        Team-->
                                    <!--                                    </router-link >-->
                                    <router-link
                                        v-show="item.href === '/users' ? onlyManagerAndAdminCanAccess : allUserLevelsCanAccess"
                                        @mouseup="sidebarOpen = false" v-for="item in computedNavigation"
                                        :key="item.name"
                                        :to="item.href"
                                        :class="[currentRouteName === item.name ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']" >
                                        <component :is="item.icon"
                                                   :class="[currentRouteName === item.name ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']"
                                                   aria-hidden="true" />
                                        {{ item.name }}
                                    </router-link >
                                </nav >
                            </div >

                            <div class="flex-shrink-o overflow-y-auto pb-4" >
                                <nav class="mt-5 space-y-1 px-2" >
                                    <a href="https://beckmann.notion.site/Knowledge-Base-Deutsch-74fad89d0fc34358a2445ebbcbf01c2c"
                                       target="_blank"
                                       class="cursor-pointer text-gray-600 hover:bg-white hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md" >
                                        <AcademicCapIcon
                                            class="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6'"
                                            aria-hidden="true" />
                                        Knowledge Base
                                    </a >

                                    <router-link @mouseup="sidebarOpen = false" v-for="item in footerNavigation"
                                                 :key="item.name" :to="item.href"
                                                 :class="[currentRouteName === item.name ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']" >
                                        <component :is="item.icon"
                                                   :class="[currentRouteName === item.name ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']"
                                                   aria-hidden="true" />
                                        {{ item.name }}
                                    </router-link >
                                </nav >
                            </div >
                        </DialogPanel >
                    </TransitionChild >
                    <div class="w-14 flex-shrink-0" >
                        <!-- Force sidebar to shrink to fit close icon -->
                    </div >
                </div >
            </Dialog >
        </TransitionRoot >

        <!-- Static sidebar for desktop -->
        <div class="hidden md:fixed md:inset-y-0 w-14 xl:w-64 md:flex md:flex-col !z-10" >
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-slate-100" >
                <div class="flex flex-1 flex-col pt-5 pb-4" >
                    <div class="flex items-center justify-between px-4 flex-col xl:flex-row" >
                        <div class="mb-4 xl:mb-0" >
                            <img class="h-auto w-full xl:h-8 w-auto" src="/images/logo.webp"
                                 alt="Beckmann Systemlogistik GmbH" />
                        </div >
                        <!--                        <NotificationMenu ></NotificationMenu >-->
                    </div >
                    <nav class="mt-5 flex-1 space-y-1 bg-slate-100 px-2" >
                        <!--                        <router-link-->
                        <!--                            v-show="onlyTeamAndAdminCanAccess"-->
                        <!--                            @mouseup="sidebarOpen = false"-->
                        <!--                            to="/team"-->
                        <!--                            :class="[currentRouteName === 'Team' ? 'bg-white shadow-sm text-gray-900' : 'text-gray-600 hover:bg-white hover:text-gray-900', 'group flex items-center px-2 py-2 font-medium rounded-md']" >-->
                        <!--                            <component is="ListBulletIcon"-->
                        <!--                                       :class="[currentRouteName === 'Team' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']"-->
                        <!--                                       aria-hidden="true" />-->
                        <!--                            <span class="hidden xl:block" >Team</span >-->
                        <!--                        </router-link >-->
                        <router-link
                            v-show="item.href === '/users' ? onlyManagerAndAdminCanAccess : allUserLevelsCanAccess"
                            v-for="item in computedNavigation" @mouseup="sidebarOpen = false" :key="item.name"
                            :to="item.href"
                            :class="[currentRouteName === item.name ? 'bg-white shadow-sm text-gray-900' : 'text-gray-600 hover:bg-white hover:text-gray-900', 'group flex items-center px-2 py-2 font-medium rounded-md']" >
                            <component :is="item.icon"
                                       :class="[currentRouteName === item.name ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']"
                                       aria-hidden="true" />
                            <span class="hidden xl:block" >{{ item.name }}</span >
                            <span v-if="item.count"
                                  class="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs/5 font-medium text-gray-600 ring-1 ring-inset ring-gray-200"
                                  aria-hidden="true" >{{ item.count }}</span >
                        </router-link >
                    </nav >
                </div >

                <div class="flex-shrink-o overflow-y-auto pb-4" >
                    <nav class="mt-5 space-y-1 px-2" >
                        <a href="https://beckmann.notion.site/Knowledge-Base-Deutsch-74fad89d0fc34358a2445ebbcbf01c2c"
                           target="_blank"
                           class="cursor-pointer text-gray-600 hover:bg-white hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md" >
                            <AcademicCapIcon class="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6'"
                                             aria-hidden="true" />
                            <span class="hidden xl:block" >Knowledge Base</span >
                        </a >
                        <div @click="showMyAccount = true" @mouseup="sidebarOpen = false"
                             class="cursor-pointer text-gray-600 hover:bg-white hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md" >
                            <UserCircleIcon class="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6'"
                                            aria-hidden="true" />
                            <span class="hidden xl:block" >Mein Account</span >
                        </div >
                        <router-link @mouseup="sidebarOpen = false" v-for="item in footerNavigation" :key="item.name"
                                     :to="item.href"
                                     :class="[currentRouteName === item.name ? 'bg-white shadow-sm text-gray-900' : 'text-gray-600 hover:bg-white hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']" >
                            <component :is="item.icon"
                                       :class="[currentRouteName === item.name ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']"
                                       aria-hidden="true" />
                            <span class="hidden xl:block" >{{ item.name }}</span >
                        </router-link >
                    </nav >
                </div >
            </div >
        </div >
        <div class="flex flex-1 flex-col md:pl-14 xl:pl-64 " >
            <div class="sticky top-0 z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden" >
                <button type="button"
                        class="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        @click="sidebarOpen = true" >
                    <span class="sr-only" >Open sidebar</span >
                    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </button >
            </div >
            <main class="flex-1 bg-white min-h-[100vh] !z-0" >
                <div class="pt-10" >
                    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8" >
                        <h1 class="text-3xl font-semibold text-gray-900" >{{ currentRouteName }}</h1 >
                    </div >
                    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 " >
                        <div class="py-3" >
                            <router-view />
                        </div >
                    </div >
                </div >
            </main >
        </div >
    </div >

    <main class="flex-1 h-full" v-if="currentRoutePath === '/login' || currentRoutePath === '/forgot-password'" >
        <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 h-full" >
            <router-view />
        </div >
    </main >

    <Modal :show="showMyAccount" @modalClose="showMyAccount = false" >
        <template v-slot:body >
            <AccountSettings @closeMe="showMyAccount = false" />
        </template >
    </Modal >
</template >

<script >
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {
    AcademicCapIcon,
    ArrowLeftStartOnRectangleIcon,
    ArrowPathRoundedSquareIcon,
    Bars3Icon,
    CalendarIcon,
    ChartBarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentTextIcon,
    FolderIcon,
    FolderPlusIcon,
    HomeIcon,
    InboxIcon,
    ListBulletIcon,
    QueueListIcon,
    RectangleStackIcon,
    UserCircleIcon,
    UsersIcon,
    XMarkIcon
} from '@heroicons/vue/24/outline';
import AccountSettings from "../components/AccountSettings.vue";
import Modal from "../components/Modal.vue";
import Cookies from 'js-cookie'
import NotificationMenu from "../components/NotificationMenu.vue";

export default {
    name: "App",

    components: {
        NotificationMenu,
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        Bars3Icon,
        CalendarIcon,
        ChartBarIcon,
        FolderIcon,
        HomeIcon,
        InboxIcon,
        ListBulletIcon,
        XMarkIcon,
        ArrowPathRoundedSquareIcon,
        FolderPlusIcon,
        DocumentTextIcon,
        QueueListIcon,
        RectangleStackIcon,
        AcademicCapIcon,
        Cog6ToothIcon,
        ArrowLeftStartOnRectangleIcon,
        UserCircleIcon,
        AccountSettings,
        Modal,
        UsersIcon,
    },

    data() {
        return {
            footerNavigation: [
                {name: 'Logout', href: '/logout', icon: ArrowLeftStartOnRectangleIcon},
            ],
            sidebarOpen: false,
            showMyAccount: false,
            navigation: [
                {name: 'Dashboard', href: '/', icon: HomeIcon},
                {name: 'Aufträge', href: '/orders', icon: RectangleStackIcon},
                {name: 'Bestände', href: '/products', icon: QueueListIcon},
                {name: 'Retouren', href: '/rma', icon: ArrowPathRoundedSquareIcon},
                {name: 'Lieferungen', href: '/goods', icon: FolderPlusIcon},
                {name: 'Kennzahlen', href: '/analytics', icon: ChartPieIcon},
                {name: 'Benutzer', href: '/users', icon: UsersIcon},
            ],
        };
    },

    computed: {
        computedNavigation() {
            return this.navigation;
        },
        currentRouteName() {
            return this.$route.name;
        },
        currentRoutePath() {
            return this.$route.path;
        },

        allUserLevelsCanAccess() {
            return Cookies.get('level') === 'customer' || Cookies.get('level') === 'manager' || Cookies.get('level') === 'admin';
        },

        onlyManagerAndAdminCanAccess() {
            return Cookies.get('level') === 'manager' || Cookies.get('level') === 'admin';
        },

        onlyTeamAndAdminCanAccess() {
            return Cookies.get('level') === 'team' || Cookies.get('level') === 'admin';
        }
    },

    methods: {
        fetchTags() {
            Cookies.set('tags', JSON.stringify([]), {expires: 1, path: ''});
            if (this.allUserLevelsCanAccess) {
                axios.get('/api/tags')
                    .then(response => {
                        if (response.data) {
                            Cookies.set('tags', JSON.stringify(response.data), {expires: 1, path: ''});
                        }
                    });
            }
        },

        async fetchUnreadRmaCount() {
            if (this.allUserLevelsCanAccess) {
                axios.get('/api/unread-rma-count')
                    .then(response => {
                        if (response.data) {
                            this.navigation[3].count = response.data;
                        }
                    });
            }
        },

        async fetchNewGoodsCount() {
            if (this.allUserLevelsCanAccess) {
                axios.get('/api/new-goods-count')
                    .then(response => {
                        if (response.data) {
                            this.navigation[4].count = response.data;
                        }
                    });
            }
        }
    },

    async mounted() {
        await this.fetchUnreadRmaCount();
        await this.fetchNewGoodsCount();

        // Fetch unread RMA count and new goods count every 20 seconds
        setInterval(() => {
            this.fetchUnreadRmaCount();
            this.fetchNewGoodsCount();
        }, 20000);
        this.fetchTags();
    }
}
</script >
